
import { Component, Vue } from 'vue-property-decorator'
import { isLoggedIn, logout } from '@/state/Authentication'
import LoginForm from '@/components/forms/LoginForm.vue'
import DropdownNavigation, { type NavigationRoute } from '@/components/elements/DropdownNavigation.vue'
import { isCurrentMember } from '@/state/Membership'
import { environment } from '@/helpers/Environment'

export type NavigationSection = Array<{
  title?: string
  disabled?: boolean
  items: Array<NavigationRoute>
}>

@Component({
  components: { DropdownNavigation, LoginForm },
})
export default class MyAccount extends Vue {
  get navigationSections(): NavigationSection {
    const pageConfig = environment.config.my_account_pages

    const result: NavigationSection = [
      {
        title: 'Membership',
        disabled: !this.hasMembership,
        items: [
          {
            label: 'My membership details',
            route: '/my-account/membership-details',
            icon: 'StarIcon',
          },
        ],
      },
    ]

    const ticketsSubpages: Array<NavigationRoute> = []

    if (pageConfig?.my_profile) {
      result.push({
        title: 'Profile',
        items: [
          {
            label: 'My profile',
            route: '/my-account/my-profile',
            icon: 'UserIcon',
          },
        ],
      })
    }

    if (pageConfig?.upcoming_events) {
      ticketsSubpages.push({
        label: 'My upcoming tickets',
        route: '/my-account/upcoming',
        icon: 'TicketIcon',
      })
    }

    if (pageConfig?.recent_orders) {
      ticketsSubpages.push({
        label: 'My recent orders',
        route: '/my-account/orders',
        icon: 'ClockRotateLeftIcon',
      })
    }

    if (ticketsSubpages.length > 0) {
      result.push({
        title: 'Tickets',
        items: ticketsSubpages,
      })
    }

    result.push({
      items: [
        {
          label: 'Logout',
          route: this.logOut,
          icon: 'LogoutIcon',
        },
      ],
    })

    return result
  }

  async logOut() {
    logout(this.$router, '/')
  }

  get activeRoute() {
    return this.$route.path
  }

  get isLoggedIn(): boolean {
    return isLoggedIn()
  }

  get hasMembership(): boolean {
    return isCurrentMember()
  }

  get flatRoutes() {
    return this.enabledNavSections.map((section) => section.items).flat()
  }

  get enabledNavSections() {
    return this.navigationSections.filter((section) => !section.disabled)
  }

  loadRoute(value) {
    if (typeof value === 'string') {
      this.$router.push(value)
    } else if (typeof value === 'function') {
      value()
    }
  }
}
